<div class="jumbotron_header">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <img src="/assets/img/logos/logo-slogan.png"/>
                <h1>Catering</h1>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron_menu">
    <img class="burrito" src="/assets/img/photos/burrito.png">
    <img class="lime" src="/assets/img/photos/lime.png">
    <img class="chips" src="/assets/img/photos/tortilla-chips.png">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>Jalapenos Catering</h1>
                <hr>
                <p style="color: var(--darkgray);">Minimum of 10 persons, then in increments of 5.</p>
                <p>To place your order we need at least 24 hours notice in advance. Orders are available for pick-up or delivery. All orders come with servingware, tongs, plates, napkins and cutlery.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <br><br>
                <ul>
                    <li>
                        <h3>Single</h3>
                        <h5>( per person )</h5>
                        <h4>$10.75</h4>
                    </li>
                </ul>
                <br><br>
                <h3>1. Choose ONE Meat:</h3>
                <br>
                <h6>- Ground Beef</h6>
                <h6>- Shredded Beef</h6>
                <h6>- Chicken</h6>
                <h6>- Carnitas</h6>
                <br>
                <h3>2. Choose TWO Bases:</h3>
                <br>
                <h6>- Beans</h6>
                <h6>- Rice</h6>
                <h6>- Double Beans</h6>
                <h6>- Double Rice</h6>
                <br>
                <h3>3. Choose TWO Toppings:</h3>
                <br>
                <h6>- Shredded Cheese</h6>
                <h6>- Shredded Lettuce</h6>
                <h6>- Sour Cream</h6>
                <br>
                <h3>4. Choose TWO Salsa's:</h3>
                <br>
                <h6>- Mild</h6>
                <h6>- Hot</h6>
                <h6>- Pico de Gallo</h6>
                <br>
                <h3>5. Choose One Tortilla:</h3>
                <br>
                <h6>- Soft Flour</h6>
                <h6>- Crispy Corn</h6>
                <h6>( 2 torts/person )</h6>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <br><br>
                <ul>
                    <li>
                        <h3>Double</h3>
                        <h5>( per person )</h5>
                        <h4>$12.75</h4>
                    </li>
                </ul>
                <br><br>
                <h3>1. Choose TWO Meats:</h3>
                <br>
                <h6>- Ground Beef</h6>
                <h6>- Shredded Beef</h6>
                <h6>- Chicken</h6>
                <h6>- Carnitas</h6>
                <br>
                <h3>2. Choose TWO Bases:</h3>
                <br>
                <h6>- Beans</h6>
                <h6>- Rice</h6>
                <h6>- Double Beans</h6>
                <h6>- Double Rice</h6>
                <br>
                <h3>3. Choose TWO Toppings:</h3>
                <br>
                <h6>- Shredded Cheese</h6>
                <h6>- Shredded Lettuce</h6>
                <h6>- Sour Cream</h6>
                <br>
                <h3>4. Choose ONE Premium Side:</h3>
                <br>
                <h6>- Guacamole</h6>
                <h6>- Queso</h6>
                <br>
                <h3>5. Choose TWO Salsa's:</h3>
                <br>
                <h6>- Mild</h6>
                <h6>- Hot</h6>
                <h6>- Pico de Gallo</h6>
                <br>
                <h3>6. Choose One Tortilla:</h3>
                <br>
                <h6>- Soft Flour</h6>
                <h6>- Crispy Corn</h6>
                <h6>( 2 torts/person )</h6>
                <br>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h2>Plato Loco<br><b style="font-size: 12px; font-weight: 500; color:var(--lightgray);"> (Serves 10-12)</b></h2>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <ul>
                    <li>
                        <h3>Plato Loco</h3>
                        <h5>( Serves 10-12 )</h5>
                        <h4>$129.99</h4>
                    </li>
                </ul>
                <br>
                <p style="text-align: justify;">A layer of beans, your choice of meat, onion, tomato, lettuce, cheese, jalapenos and sour cream. Served with fresh chips and salsa. <br><br> Comes w/ serving spoons and 12 bowls with forks.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h2>A la Cart</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Qt. Rice</h3>
                        <h5>(6-8 servings)</h5>
                        <h4>$8.99</h4>
                    </li>
                    <li>
                        <h3>Qt. Beans</h3>
                        <h5> (6-8 servings)</h5>
                        <h4>$8.99</h4>
                    </li>
                    <li>
                        <h3>Qt. Salsa</h3>
                        <h5> (8-12 servings)</h5>
                        <h4>$13.59</h4>
                    </li>
                    <li>
                        <h3>Qt. Guac</h3>
                        <h5> (8-12 servings)</h5>
                        <h4>$21.99</h4>
                    </li>
                </ul>
            </div>
            <div class="col-lg-2 col-md-2 hidden-sm hidden-xs">
            </div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <ul>
                    <li>
                        <h3>Qt. Queso</h3>
                        <h5> (8-12 servings)</h5>
                        <h4>$20.99</h4>
                    </li>
                    <li>
                        <h3>Qt. Pico</h3>
                        <h5> (8-12 servings)</h5>
                        <h4>$13.59</h4>
                    </li>
                    <li>
                        <h3>lb. Chips</h3>
                        <h5> (8-12 servings)</h5>
                        <h4>$10.69</h4>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h2 style="margin-top: 100px;">To order catering, please give us a call at:</h2>
                <a href="tel:15174822326"><i class="fas fa-phone"></i>(517) 482-2326</a>
            </div>
        </div>
    </div>
</div>